body,
html {
  height: 100%;
  padding-top: 0.5%;
}
.imageCenter {
  margin: auto;
  width: 100%;
  text-align: center;
  padding: 2px;
  background-color: white;
}

#signOut {
  cursor: pointer;
}

.dateFilter {
  padding-left: 5em;
  padding-top: 5em;
  padding-bottom: 5em;
  padding-right: 5em;
}

.submit {
  display: none;
}

.divRightAlign {
  text-align: right;
}

.divLeftAlign {
  text-align: left;
}

.nav-tabs .nav-link.active {
  border-bottom-style: dashed;
  border-top-color: black;
  border-right-color: black;
  border-left-color: black;
}

.react-datepicker-popper {
  z-index: 10 !important;
}
