#title {
    text-align: center;
    font-family: arial, sans-serif;
  }
  
  #ManifestShipmentGrid caption{
    caption-side: top;
  }
  .ManifestShipmentGrid {
    font-family: Arial, Helvetica, sans-serif;
    font-size: small;
    border: 1px solid #ddd;
    border-radius: .10em;
    width: 100%;
    align-items: center;
    
  }
  
  #ManifestShipmentGrid thead tr th {
    text-align: center;
    position: sticky;
    top:0px;
    background-color:#454545;
  }

  #ManifestShipmentGrid td, #ManifestShipmentGrid th {
    border: 1px solid #ddd;
  }
  
 
  #ManifestShipmentGrid tr:nth-child(even){background-color: #f2f2f2;}
  
  #ManifestShipmentGrid tr:hover {background-color: #ddd;}
  
  #ManifestShipmentGrid th {
    padding-top: 15px;
    padding-bottom: 12px;
    text-align: center;
    background-color : #454545;
    color: white;
  }

  .archive {
    text-align: center;
  }

  
.gridHolderSummary{
  border: 1px solid #454545;
  border-radius: .5em;
  height:20em;
  overflow-y:auto;
  width:100%;
  align-content: center;
}

#faTrashAlt{
  cursor:pointer;
}

#CheckboxColor{
  background-color: black;
}

#statusTextArea{
  font-size: .8em;
  border-radius:.2em ;
  color: red;
}

.divRightAlign {
  text-align: right;
}

.badge.block-badge {
  text-align: left;
  display: inline-block;
  width: 20em;
}

.alert.block-badge {
  text-align: left;
  z-index: -1;
  display: inline-block;
  width: 20em;
}

.rounded {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
