#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#ShipmentGrid caption {
  caption-side: top;
}

.ShipmentGrid {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  border: 1px solid #ddd;
  border-radius: 0.1em;
  width: 100%;
}

#ShipmentGrid thead tr th {
  text-align: center;
  vertical-align: middle;
  position: sticky;
  top: 0;
  background-color: #454545;
}

#ShipmentGrid td,
#ShipmentGrid th {
  border: 1px solid #ddd;
}

#ShipmentGrid tr:nth-child(even) {
  background-color: #f2f2f2;
}

#ShipmentGrid tr:hover {
  background-color: #ddd;
}

#ShipmentGrid th {
  padding-top: 15px;
  text-align: center;
  background-color: #454545;
  color: white;
}

.archive {
  text-align: center;
}

.gridHolderShipmentGrid {
  border: 1px solid #454545;
  border-radius: 0.5em;
  height: 32em;
  /*675 px = 42.1875 em*/
  /*600 px = 37.5 em*/
  /* overflow-y:auto; */
}

#faTrashAlt {
  cursor: pointer;
}

#CheckboxColor {
  background-color: black;
}

#statusTextArea {
  font-size: 0.8em;
  border-radius: 0.2em;
  color: red;
}

.divRightAlign {
  text-align: right;
}

.badge.block-badge {
  text-align: left;
  display: inline-block;
  width: 20em;
}

.alert.block-badge {
  text-align: left;
  z-index: -1;
  display: inline-block;
  width: 20em;
}

.rounded {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
#editName {
  cursor: pointer;
}

.badge-message {
  white-space: normal;
  padding: 4px 8px;
}
